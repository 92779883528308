<template>
  <div class="leader-board-group-list1">
    <el-table v-bind:data="propData" border>
      <el-table-column label="Tag" width="320px">
        <template slot-scope="{ row }">
          <span>{{ row.tag }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Boss Caption" width="240px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.bossCaption"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.bossCaption }}</span>
        </template>
      </el-table-column>

      <el-table-column label="BossID" width="120px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.bossID"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.bossID }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Level" width="80px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.level"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.level }}</span>
        </template>
      </el-table-column>

      <el-table-column label="MaxHP" width="128px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.maxHP"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.maxHP }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Reward Name" width="320px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-select
              v-model="row.rewardName"
              value-key="rewardName"
              filterable
              allow-create
              placeholder="Select"
              @change="handleChange($event)"
              style="width: 100%"
            >
              <el-option
                v-for="item in propRewardList"
                :key="item.rewardName"
                :label="item.rewardName"
                :value="item.rewardName"
              >
              </el-option>
            </el-select>
          </template>
          <span v-else>
            {{ row.rewardName }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";

export default {
  props: {
    propID: {
      type: Number,
    },
    propData: {
      type: Array,
    },
    propRewardList: {
      type: Array,
    },
    propEditMode: {
      type: Boolean,
    },
  },
  components: {
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData,
      });
    },
    CallbackChangeRewardData(data) {
    },
    OnHandleRewardFileUpload(file, fileList) {
      Message({
        message: "OnHandleRewardFileUpload content: " + file.raw,
        type: "error",
        duration: 5 * 1000,
      });
    },
  },
};
</script>