<template>
  <div class="leader-board-group-list">
    <el-table v-bind:data="propData" border>

      <el-table-column label="Reward Name" width="220px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.rewardName"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.rewardName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Rewards" width="1850px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <div>
              <input
                type="file"
                @change="onFileChange($event, row.rewardName)"
              />
            </div>

            <leader-board-reward-list
              v-bind:propData="row.rewards"
              v-bind:propEditMode="propEditMode"
              v-on:CallbackChangeData="CallbackChangeRewardData"
            ></leader-board-reward-list>
          </template>
          <span v-else>
            <leader-board-reward-list
              v-bind:propData="row.rewards"
              v-bind:propEditMode="propEditMode"
              v-on:CallbackChangeData="CallbackChangeRewardData"
            ></leader-board-reward-list>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import LeaderBoardRewardList from "@/components/CompLeaderBoardRewardList";

import {
  TryParseUploadFile_ReadTextFile,
  HelperParseRewardFromFile,
} from "@/api/gameservice";

export default {
  props: {
    propID: {
      type: Number,
    },
    propData: {
      type: Array,
    },
    propEditMode: {
      type: Boolean,
    },
  },
  components: {
    LeaderBoardRewardList,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData,
      });
    },
    CallbackChangeRewardData(data) {
      //Message({
      //            message: "CallbackChangeRewardData: " + JSON.stringify(data),
      //            type: "success",
      //            duration: 5 * 1000
      //          });
    },
    OnHandleRewardFileUpload(file, fileList) {
      /*Message({
        message: "OnHandleRewardFileUpload content: " + file.raw,
        type: "error",
        duration: 5 * 1000,
      });
      */

      let objThis = this;

      if (file) {
        TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
          if (code === 0) {
            HelperParseRewardFromFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Import rewards from file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  let rewards = [];

                  for (let idx = 0; idx < response.data.rewards.length; idx++) {
                    let itemGift = JSON.parse(response.data.rewards[idx].gifts);
                    if (!itemGift) {
                      Message({
                        message:
                          "Invalid Gift content: " +
                          response.data.rewards[idx].gifts,
                        type: "error",
                        duration: 5 * 1000,
                      });
                      return;
                    }

                    rewards.push({
                      id: Number(response.data.rewards[idx].id),
                      //eventId: response.data.rewards[idx].eventId,
                      title: response.data.rewards[idx].title,
                      rankMin: response.data.rewards[idx].rankMin,
                      rankMax: response.data.rewards[idx].rankMax,
                      requiredPoint: response.data.rewards[idx].requiredPoint,
                      gifts: response.data.rewards[idx].gifts,
                    });
                  }

                  /*Message({
                    message:
                      "OnHandleRewardFileUpload content: " +
                      JSON.stringify(rewards),
                    type: "success",
                    duration: 5 * 1000,
                  });*/

                  objThis.$emit("CallbackChangeReward", {
                    id: 1,
                    name: "name",
                    rewards: rewards,
                  });
                } else {
                  Message({
                    message: "Set Helper Parse Reward From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[HelperParseRewardFromFile]Set LeaderBoard All Settings 5: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message: "Set OnHandleRewardFileUpload code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
    onFileChange(e, rewardName) {
      var files = e.target.files || e.dataTransfer.files;

      /*Message({
        message: "file length: " + files.length + ", name:" + name,
        type: "error",
        duration: 5 * 1000,
      });
      */

      let objThis = this;

      if (!files.length) return;

      if (files[0]) {
        TryParseUploadFile_ReadTextFile(files[0], function (code, data) {
          if (code === 0) {
            HelperParseRewardFromFile(data)
              .then((response) => {
                if (response.data.code === 0) {
                  Message({
                    message: "Import rewards from file succsessfully",
                    type: "success",
                    duration: 5 * 1000,
                  });

                  let rewards = [];

                  for (let idx = 0; idx < response.data.rewards.length; idx++) {
                    let itemGift = JSON.parse(response.data.rewards[idx].gifts);
                    if (!itemGift) {
                      Message({
                        message:
                          "Invalid Gift content: " +
                          response.data.rewards[idx].gifts,
                        type: "error",
                        duration: 5 * 1000,
                      });
                      return;
                    }

                    rewards.push({
                      id: Number(response.data.rewards[idx].id),
                      //eventId: response.data.rewards[idx].eventId,
                      title: response.data.rewards[idx].title,
                      rankMin: response.data.rewards[idx].rankMin,
                      rankMax: response.data.rewards[idx].rankMax,
                      requiredPoint: response.data.rewards[idx].requiredPoint,
                      gifts: response.data.rewards[idx].gifts,
                    });
                  }

                  objThis.$emit("CallbackChangeRewardList", {
                    id: objThis.propID,
                    rewardName: rewardName,
                    rewards: rewards,
                  });
                } else {
                  Message({
                    message: "Set Helper Parse Reward From File ",
                    type: "error",
                    duration: 5 * 1000,
                  });
                }
              })
              .catch((error) => {
                //loadingInstance.close();
                Message({
                  message:
                    "[HelperParseRewardFromFile]Set LeaderBoard All Settings 5: " +
                    error,
                  type: "error",
                  duration: 5 * 1000,
                });
              });
          } else {
            Message({
              message: "Set OnHandleRewardFileUpload code: " + code,
              type: "error",
              duration: 5 * 1000,
            });
          }
        });
      }
    },
  },
};
</script>