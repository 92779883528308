<template>
  <div class="leader-board-group-list">
    <el-table v-bind:data="propData" border>
      <el-table-column label="Name" width="200px">
        <template slot-scope="{ row }">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Date" width="240px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            From:
            <el-date-picker
              v-model="row.startDate"
              type="datetime"
              placeholder="Select date and time"
              format="dd/MM/yyyy HH:mm:ss"
            >
            </el-date-picker>
            <br /><br />
            To:
            <el-date-picker
              v-model="row.endDate"
              type="datetime"
              placeholder="Select date and time"
              format="dd/MM/yyyy HH:mm:ss"
            >
            </el-date-picker>
          </template>
          <span v-else
            >From:
            {{ row.startDate.toLocaleString("en-GB", { hour12: false }) }} }}
            <br /><br />
            To:
            {{ row.endDate.toLocaleString("en-GB", { hour12: false }) }} }}</span
          >
        </template>
      </el-table-column>

      <el-table-column label="Info" width="1400px">
        <template slot-scope="{ row }">
          <little-boss-info
            v-bind:propID="propID"
            v-bind:propData="row.info"
            v-bind:propRewardList="propRewardList"
            v-bind:propEditMode="propEditMode"
            v-on:CallbackChangeData="CallbackChangeGroupData"
          ></little-boss-info>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import LeaderBoardRewardList from "@/components/CompLeaderBoardRewardList";
import LittleBossInfo from "@/components/CompLittleBossInfo";

import {
  TryParseUploadFile_ReadTextFile,
  HelperParseRewardFromFile,
} from "@/api/gameservice";

export default {
  props: {
    propID: {
      type: Number,
    },
    propData: {
      type: Array,
    },
    propRewardList: {
      type: Array,
    },
    propEditMode: {
      type: Boolean,
    },
  },
  components: {
    LittleBossInfo,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData,
      });
    },
    CallbackChangeRewardData(data) {
    },
    OnHandleRewardFileUpload(file, fileList) {
      Message({
        message: "OnHandleRewardFileUpload content: " + file.raw,
        type: "error",
        duration: 5 * 1000,
      });
    },
    CallbackChangeGroupData(data) {
      /*Message({
        message: "CallbackChangeGroupData: " + JSON.stringify(data),
        type: "success",
        duration: 5 * 1000,
      });*/
    },
  },
};
</script>