<template>
  <div class="leader-board-group-list">
    <el-table v-bind:data="propData" border>
      <el-table-column label="Name" width="250px">
        <template slot-scope="{ row }">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Count" width="85px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.count"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.count }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Payment Size" width="85px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.payment_size"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.payment_size }}</span>
        </template>
      </el-table-column>

      <el-table-column label="None Payment (Fast Fill)" width="118px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.none_payment_limit_size_1"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.none_payment_limit_size_1 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Total Size" width="92px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-input
              placeholder="Please input value"
              v-model="row.size"
              @change="handleChange"
            ></el-input>
          </template>
          <span v-else>{{ row.size }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Rewards" width="250px">
        <template slot-scope="{ row }">
          <template v-if="propEditMode">
            <el-select
              v-model="row.rewardName"
              value-key="rewardName"
              filterable
              allow-create
              placeholder="Select"
              @change="handleChange($event)"
              style="width: 100%"
            >
              <el-option
                v-for="item in propRewardList"
                :key="item.rewardName"
                :label="item.rewardName"
                :value="item.rewardName"
              >
              </el-option>
            </el-select>
          </template>
          <span v-else>
            {{ row.rewardName }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";

export default {
  props: {
    propID: {
      type: Number,
    },
    propData: {
      type: Array,
    },
    propRewardList: {
      type: Array,
    },
    propEditMode: {
      type: Boolean,
    },
  },
  components: {
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleChange() {
      this.$emit("CallbackChangeData", {
        id: this.propID,
        data: this.propData,
      });
    },
    CallbackChangeRewardData(data) {
      //Message({
      //            message: "CallbackChangeRewardData: " + JSON.stringify(data),
      //            type: "success",
      //            duration: 5 * 1000
      //          });
    },
  },
};
</script>