var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leader-board-group-list1"},[_c('el-table',{attrs:{"data":_vm.propData,"border":""}},[_c('el-table-column',{attrs:{"label":"Tag","width":"320px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.tag))])]}}])}),_c('el-table-column',{attrs:{"label":"Boss Caption","width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.bossCaption),callback:function ($$v) {_vm.$set(row, "bossCaption", $$v)},expression:"row.bossCaption"}})]:_c('span',[_vm._v(_vm._s(row.bossCaption))])]}}])}),_c('el-table-column',{attrs:{"label":"BossID","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.bossID),callback:function ($$v) {_vm.$set(row, "bossID", $$v)},expression:"row.bossID"}})]:_c('span',[_vm._v(_vm._s(row.bossID))])]}}])}),_c('el-table-column',{attrs:{"label":"Level","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.level),callback:function ($$v) {_vm.$set(row, "level", $$v)},expression:"row.level"}})]:_c('span',[_vm._v(_vm._s(row.level))])]}}])}),_c('el-table-column',{attrs:{"label":"MaxHP","width":"128px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.maxHP),callback:function ($$v) {_vm.$set(row, "maxHP", $$v)},expression:"row.maxHP"}})]:_c('span',[_vm._v(_vm._s(row.maxHP))])]}}])}),_c('el-table-column',{attrs:{"label":"Reward Name","width":"320px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value-key":"rewardName","filterable":"","allow-create":"","placeholder":"Select"},on:{"change":function($event){return _vm.handleChange($event)}},model:{value:(row.rewardName),callback:function ($$v) {_vm.$set(row, "rewardName", $$v)},expression:"row.rewardName"}},_vm._l((_vm.propRewardList),function(item){return _c('el-option',{key:item.rewardName,attrs:{"label":item.rewardName,"value":item.rewardName}})}),1)]:_c('span',[_vm._v(" "+_vm._s(row.rewardName)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }