var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leader-board-group-list"},[_c('el-table',{attrs:{"data":_vm.propData,"border":""}},[_c('el-table-column',{attrs:{"label":"Name","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Count","width":"85px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.count),callback:function ($$v) {_vm.$set(row, "count", $$v)},expression:"row.count"}})]:_c('span',[_vm._v(_vm._s(row.count))])]}}])}),_c('el-table-column',{attrs:{"label":"Payment Size","width":"85px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.payment_size),callback:function ($$v) {_vm.$set(row, "payment_size", $$v)},expression:"row.payment_size"}})]:_c('span',[_vm._v(_vm._s(row.payment_size))])]}}])}),_c('el-table-column',{attrs:{"label":"None Payment (Fast Fill)","width":"118px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.none_payment_limit_size_1),callback:function ($$v) {_vm.$set(row, "none_payment_limit_size_1", $$v)},expression:"row.none_payment_limit_size_1"}})]:_c('span',[_vm._v(_vm._s(row.none_payment_limit_size_1))])]}}])}),_c('el-table-column',{attrs:{"label":"Total Size","width":"92px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-input',{attrs:{"placeholder":"Please input value"},on:{"change":_vm.handleChange},model:{value:(row.size),callback:function ($$v) {_vm.$set(row, "size", $$v)},expression:"row.size"}})]:_c('span',[_vm._v(_vm._s(row.size))])]}}])}),_c('el-table-column',{attrs:{"label":"Rewards","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.propEditMode)?[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value-key":"rewardName","filterable":"","allow-create":"","placeholder":"Select"},on:{"change":function($event){return _vm.handleChange($event)}},model:{value:(row.rewardName),callback:function ($$v) {_vm.$set(row, "rewardName", $$v)},expression:"row.rewardName"}},_vm._l((_vm.propRewardList),function(item){return _c('el-option',{key:item.rewardName,attrs:{"label":item.rewardName,"value":item.rewardName}})}),1)]:_c('span',[_vm._v(" "+_vm._s(row.rewardName)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }